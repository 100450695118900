import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('instance-id')

  if (element) {
    const instanceId = element.dataset.subscription

    consumer.subscriptions.create({
        channel: "InstanceStatusChannel",
        instance_id: instanceId,
        locale: document.documentElement.lang
      }, {
        connected() {
          this.perform('current_status', { instance_id: instanceId })
        },

        disconnected() {
        },

        received(data) {
          const instructions = document.getElementById('instructions')
          instructions.classList.add('rgpd-dashboard-container')
          instructions.innerHTML = data.html;
        }
    });
  }
});
