import consumer from "./consumer"

consumer.subscriptions.create("UserNotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if(data.title == "refresh"){
      location.reload();
    }
    else if(data.title == "update")
    {
      let elment = document.getElementById(data.html_id);
      elment.innerHTML = data.html;
    }
  }
});
