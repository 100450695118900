import {
  domLoaded
} from "./ready";

domLoaded(function () {
  const contact = document.querySelector('#recaptcha-contact');
  const reg_form = document.querySelector('#recaptcha-registration');
  const newsletter = document.querySelector('#recaptcha-newsletter');

  grecaptcha.ready((e) => {
    if (contact) {
      grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, { action: 'contact' }).then((res) => {
        contact.value = res
      });
    }

    if (reg_form) {
      grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, { action: 'registration' }).then((res) => {
        reg_form.value = res
      });
    }

    if (newsletter) {
      grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, { action: 'newsletter' }).then((res) => {
        newsletter.value = res
      });
    }
  });
});
